import { TrackerData } from "./types";

export const isDistance = (entry: TrackerData|number): entry is number => {
  return typeof entry === 'number';
}

export const isTracker = (entry: TrackerData|number): entry is TrackerData => {
  return !isDistance(entry);
}

