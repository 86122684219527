import { DateTime } from "luxon";
import { useMemo } from "react";
import { getSolarAngle } from "../solar_angle";
import { DataMap, GPSPosition, GroupData, TrackerStructure } from "../types";
import { Slope } from "./slope";
import { TrackerContainer } from "./trackercontainer";
import { TrackerLabels } from "./tracker_labels";

export interface TrackClickEvent {
  x: number;
  y: number;
}

export interface GroupViewProps {
  position: GPSPosition | undefined;
  group: GroupData | undefined;
  time: DateTime | undefined;
  data: DataMap | undefined;
  canvasHeight: number | undefined;
  allowScale?: boolean;
  baseLines?: boolean;
  labels?: boolean;
  onTrackClick?: (event: TrackClickEvent) => void;
  visibleArea?: [number,number];
}

export const GroupView : React.FC<GroupViewProps> = ({ position, group, data, canvasHeight, time, allowScale, baseLines, labels, onTrackClick, visibleArea }) => {
  const { minHeight, maxHeight, maxWidth, distance } = useMemo(() => {
    let minHeight = Infinity;
    let maxHeight = -Infinity;
    let maxWidth = -Infinity;
    let distance = 0;

    for(const entry of group ?? []) {
      if(typeof entry === 'number') {
        distance += entry;
      } else {
        minHeight = Math.min(entry.height, minHeight);
        maxHeight = Math.max(entry.height, maxHeight);
        maxWidth = Math.max(entry.width, maxWidth);
      }
    }

    return { minHeight, maxHeight, maxWidth, distance };
  }, [group]);

  const solarAngle = useMemo(() => {
    if(group == null || position == null || time == null) {
      return null;
    }

    let count = 0;
    let sum = 0;

    for(let entry of group) {
      if(typeof entry === 'number') {
        continue;
      }

      sum += entry.azimuth;
      count += 1;
    }

    const azimuth = sum / count;

    return getSolarAngle(position, time, azimuth);
  }, [position, group, time]);

  if(canvasHeight == undefined || group == null) {
    return null;
  }

  const labelHeight = labels ? 60 : 0;
  const baseHeight = minHeight - maxWidth * 0.25;

  const poleHeight = maxWidth * 0.7;
  const rawPadding = maxWidth * 0.7;
  const scaleFactor = (canvasHeight - labelHeight) / (maxHeight - baseHeight + poleHeight + rawPadding);
  const canvasWidth = (distance + rawPadding * 2) * scaleFactor;
  const padding = rawPadding * scaleFactor;
  //const padding = 0

  let sizeOptions;

  if(allowScale) {
    sizeOptions = {
      preserveAspectRatio: "xMidYMid meet",
      viewBox: `0 0 ${canvasWidth} ${canvasHeight}`,
    };
  } else {
    sizeOptions = {
      width: canvasWidth,
      height: canvasHeight,
    };
  }

  let labelsView;

  if(labels) {
    labelsView = <>
      <rect x={-padding} y={0} width={canvasWidth} height={labelHeight} fill="white" />
      <TrackerLabels group={group} data={data} minHeight={minHeight} solarAngle={solarAngle} scaleFactor={scaleFactor} />
    </>;
  }

  let clickRect;

  if(onTrackClick != null) {

    const doScroll = (event: React.MouseEvent<SVGRectElement>) => {
      // TODO
      const target: any = event.target;
      const svg = target.parentElement as any;

      const svgPoint = svg.createSVGPoint();
      svgPoint.x = event.clientX - svg.clientLeft;
      svgPoint.y = event.clientY - svg.clientTop;

      const scaledPoint = svgPoint.matrixTransform(svg.getScreenCTM().inverse());

      const x = scaledPoint.x / canvasWidth;
      const y = scaledPoint.y / (canvasHeight - labelHeight);

      onTrackClick({ x, y });
    };

    const handleMove = (e: React.MouseEvent<SVGRectElement>) => {
      if(e.buttons & 1) {
        doScroll(e);
      }
    };

    const handleDown = (e: React.MouseEvent<SVGRectElement>) => {
      if(e.button & 1) {
        doScroll(e);
      }
    };

    const cursor = "crosshair";

    clickRect = <rect pointerEvents="all" id="track_click" x={0} y={0} width={canvasWidth} height={canvasHeight - labelHeight} fill="none" onMouseDown={handleDown} onMouseMove={handleMove} onClick={doScroll} style={{cursor}} />
  }

  let visibleView;

  if(visibleArea != null) {
    const [from, to] = visibleArea;

    visibleView = <>
      <rect x={0} y={0} width={canvasWidth * from} height={canvasHeight - labelHeight} fill="#ddd" />
      <rect x={canvasWidth * to} y={0} width={canvasWidth} height={canvasHeight - labelHeight} fill="#ddd" />
    </>;
  }

  return (
    <svg version="1.1" baseProfile="full" xmlns="http://www/w3/org/2000/svg" {...sizeOptions}>
      {visibleView}
      <g id="track" transform={`translate(${padding} ${canvasHeight - labelHeight})`}>
        <TrackerContainer group={group} data={data} poleHeight={poleHeight} baseHeight={baseHeight} scaleFactor={scaleFactor} solarAngle={solarAngle} />
        <Slope group={group} baseHeight={baseHeight} padding={padding} scaleFactor={scaleFactor} baseLines={baseLines} />
        {labelsView}
      </g>
      {clickRect}
    </svg>
  );
}
