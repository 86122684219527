import { useMemo } from "react";
import { TrackerData, TrackerGraphicalData, TrackerSlopeData } from "../types";

export interface TrackerProps {
  tracker: TrackerData;
  angle: number | undefined;
  x: number;
  poleHeight: number;
  baseHeight: number;
  scaleFactor: number;
  solarAngle: number | null;
}

export const Tracker:React.FC<TrackerProps> = ({ tracker, angle, x, baseHeight, poleHeight, scaleFactor, solarAngle }) => {
  const isDefined = angle !== undefined; 
  const panelWidth = tracker.width * scaleFactor;
  const panelHeight = Math.floor(tracker.height * scaleFactor);

  const lineWidth = 0.08 * scaleFactor;

  const rotation = useMemo(() => {
    if (angle !== undefined) {
      return `rotate(${angle} 0 0)`;
    }
  }, [angle]);

  const position = useMemo(() => {
    return `translate(${x * scaleFactor} ${(tracker.height - baseHeight + poleHeight) * -scaleFactor})`;
  }, [x, tracker, scaleFactor]);

  return <>
    <g transform={`translate(${x * scaleFactor} 0)`}>
      <g transform={`translate(0 ${(tracker.height - baseHeight + poleHeight) * -scaleFactor})`}>
        <rect x={-lineWidth / 2} y={lineWidth / 2} width={lineWidth} height={poleHeight * scaleFactor} rx={lineWidth} fill="grey" />
        <g transform={rotation}>
          <g transform={` translate(${tracker.width / 2 * -scaleFactor} 0) rotate(${-(angle ?? 0)})`}>
            <TrackerRay angle={solarAngle} length={999 * poleHeight * scaleFactor} scaleFactor={scaleFactor} />
          </g>
          <path d={`M${-panelWidth/2},0 q${lineWidth},${lineWidth} ${lineWidth*4},${lineWidth} h${panelWidth-lineWidth*8} q${lineWidth*3},0 ${lineWidth*4},${-lineWidth} z`} fill={isDefined ? '#00008b' : 'grey'} />
        </g>
      </g>
    </g>
  </>;
}

export interface TrackerRayProps {
  angle: number | null;
  length: number;
  scaleFactor: number;
}

export const TrackerRay:React.FC<TrackerRayProps> = ({ angle, length, scaleFactor }) => {
  if(angle == null) {
    return null;
  }

  const strokeWidth = 0.02 * scaleFactor;

  return (
    <line transform={`rotate(${-angle} 0 0)`} x1={0} y1={length} x2={0} y2={-length} stroke={'orange'} style={{strokeWidth}} />
  );
}
