import { FC, memo } from "react";
import { DataMap, GroupData, TrackerData } from "../types";
import { isDistance, isTracker } from "../util";

interface DistanceLabelProps {
  distance: number;
  rise: number;
  x: number;
}

const DistanceLabel: FC<DistanceLabelProps> = ({ distance, rise, x }) => {
  let rightRiseArrow, leftRiseArrow;

  if(rise > 0) {
    leftRiseArrow = <>&#x2193;</>;
    rightRiseArrow = <>&#x2191;</>;
  } else {
    leftRiseArrow = <>&#x2191;</>;
    rightRiseArrow = <>&#x2193;</>;
  }

  return (
    <g transform={`translate(${x})`}>
      <text x={0} y={20} textAnchor="middle" style={{fontSize: '12'}}>{leftRiseArrow} {Math.abs(rise).toFixed(2)}m {rightRiseArrow}</text>
      <text x={0} y={48} textAnchor="middle" style={{fontSize: '12'}}>&#x2190; {distance.toFixed(2)}m &#x2192;</text>
    </g>
  );
}

interface TrackerLabelProps {
  tracker: TrackerData;
  x: number;
  angle: number | undefined;
  minHeight: number;
  solarAngle: number | null;
}

const TrackerLabel: FC<TrackerLabelProps> = ({ x, tracker, angle, minHeight, solarAngle }) => {
  let multiNote = null;

  if(tracker.groups.size > 1) {
    multiNote = ` (x${tracker.groups.size})`;
  }

  let angleOffsetText = null;

  if(solarAngle != null && angle != null) {
    angleOffsetText = <> (&#x2739;&Delta; {Math.abs(angle - -solarAngle).toFixed(0)}&deg;)</>;
  }

  return (
    <g transform={`translate(${x})`}>
      <text x={0} y={20} textAnchor="middle" style={{fontSize: '12'}}>{tracker.id}{multiNote}</text>
      <text x={0} y={34} textAnchor="middle" style={{fontSize: '12'}}>{tracker.height.toFixed(2)}m (&Delta;{(tracker.height - minHeight).toFixed(2)}m)</text>
      <text x={0} y={48} textAnchor="middle" style={{fontSize: '12'}}>{angle ?? '-'}&deg;{angleOffsetText}</text>
    </g>
  );
}

export interface TrackerLabelsProps {
  group: GroupData;
  scaleFactor: number;
  data: DataMap | undefined;
  minHeight: number;
  solarAngle: number | null;
}

export const TrackerLabels: FC<TrackerLabelsProps> = memo(({ group, scaleFactor, data, ...options }) => {
  const labels = [];
  let offset = 0;

  for(const [index, entry] of group.entries()) {
    if(isDistance(entry)) {
      const from = group[index-1];
      const to = group[index+1];

      const rise = isTracker(from) && isTracker(to) ? to.height - from.height : 0;
      labels.push(<DistanceLabel key={index} x={(offset + entry / 2) * scaleFactor} distance={entry} rise={rise} />);
      offset += entry;
    } else {
      labels.push(<TrackerLabel key={index} x={offset * scaleFactor} tracker={entry} angle={data?.get(entry.id)} {...options} />);
    }
  }

  return <>
    {labels}
  </>
});
