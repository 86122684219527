import { DateTime } from 'luxon';
import * as SunCalc from 'suncalc';

export const getSolarAngle = (position: [number,number], when: DateTime, axisAzimuthDeg: number, axisTiltDeg=0) => {
  const sunPos = SunCalc.getPosition(when.toJSDate(), position[0], position[1]);

  if(sunPos.altitude < 0) {
    return null;
  }

  const axisAzimuth = axisAzimuthDeg / 180 * Math.PI;
  const axisTilt = axisTiltDeg / 180 * Math.PI;
  const solarZenith = Math.PI / 2 - sunPos.altitude;
  const solarAzimuth = sunPos.azimuth;

  const sinZenith = Math.sin(solarZenith);
  const x = sinZenith * Math.sin(solarAzimuth);
  const y = sinZenith * Math.cos(solarAzimuth);
  const z = Math.cos(solarZenith);

  const cosAxisAzimuth = Math.cos(axisAzimuth);
  const sinAxisAzimuth = Math.sin(axisAzimuth);

  const cosAxisTilt = Math.cos(axisTilt);
  const sinAxisTilt = Math.sin(axisTilt);

  const xp = x * cosAxisAzimuth - y * sinAxisAzimuth;
  const yp = x * cosAxisTilt * sinAxisAzimuth + y * cosAxisTilt * cosAxisAzimuth + z * sinAxisTilt;
  const zp = x * sinAxisTilt * sinAxisAzimuth + y * sinAxisTilt * cosAxisAzimuth + z * cosAxisTilt;

  const wid = Math.atan2(xp, zp);

  return wid / Math.PI * 180;
}

