import { ChangeEvent, FC, useRef, useState } from "react";

import { StructureData, TimeSeries } from "../types";
import { importAny } from "../import";

export interface ImportButtonProps {
  setStructure: (value: StructureData) => void;
  setData: (value: TimeSeries) => void;
}

export const ImportButton: FC<ImportButtonProps> = ({ setStructure, setData }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if(files == null) {
      return;
    }

    const file = files[0];

    if(file == null) {
      return;
    }

    const fileReader = new FileReader();

    fileReader.onload = async (loadEvent) => {
      try {
        const raw = fileReader.result as string;
        const { structure, data } = importAny(raw);

        if(data == null && structure == null) {
          alert("File did not contain any data");
        }

        if(structure) {
          setStructure(structure);
        }

        if(data) {
          setData(data);
        }
      } catch {
        alert("Unable to parse file");
      }
    };

    fileReader.onerror = () => {
      alert("Unable to load file");
    };

    fileReader.readAsBinaryString(file);
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  return <>
    <button onClick={handleClick}>Import</button>
    <input style={{ display: 'none' }} ref={fileInputRef} type="file" value="" onChange={onChange} />
  </>;
}
