import { memo } from "react";
import { GroupData, TrackerData } from "../types";
import { isDistance, isTracker } from "../util";

export interface SlopeProps {
  group: GroupData;
  baseHeight: number;
  padding: number
  scaleFactor: number;
  baseLines?: boolean;
}

export const Slope:React.FC<SlopeProps> = memo(({ group, baseHeight, padding, scaleFactor, baseLines }) => {
  if(group.length === 0) {
    return null;
  }

  const points = [];
  const lines = [];
  let offset = 0;

  const strokeWidth = 0.02 * scaleFactor;

  for(const [index, entry] of group.entries()) {
    if(isDistance(entry)) {
      const from = group[index-1];
      const to = group[index+1];

      if(baseLines && isTracker(from) && isTracker(to)) {
        const y = (Math.min(from.height, to.height) - baseHeight) * -scaleFactor;
        lines.push(<line key={index} x1={offset*scaleFactor} y1={y} x2={(offset+entry)*scaleFactor} y2={y} stroke="black" style={{strokeWidth}} />);
      }

      offset += entry;
    } else {
      points.push(`${offset * scaleFactor},${(entry.height - baseHeight) * -scaleFactor}`);
    }
  }

  const firstY = ((group[0] as TrackerData).height - baseHeight) * -scaleFactor;
  const lastY = ((group[group.length-1] as TrackerData).height - baseHeight) * -scaleFactor;

  return <>
    <polygon points={`${-padding},0 ${-padding},${firstY} ${points.join(' ')} ${offset*scaleFactor+padding},${lastY} ${offset*scaleFactor+padding},0`} fill="#704700" />
    {lines}
  </>
});
