import { DateTime } from "luxon";
import { ChangeEvent, useCallback, useState } from "react";
import { TimeSeries } from "../types";

export interface TimeSliderProps {
  data: TimeSeries | undefined;
  value: number;
  update: (value: number) => void;
}

export const TimeSlider:React.FC<TimeSliderProps> = ({ data, value, update }) => {
  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    update(Number(event.target.value));
  }, [update]);

  return (
    <input type="range" value={value} min={0} max={data != null ? data.length - 1 : 0} onChange={onChange} style={{width: '65%'}} />
  );
}
