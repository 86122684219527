import { FC } from "react";

import { saveAs } from 'file-saver';

import { StructureData, TimeSeries } from "../types";
import { exportRaw } from "../import";

export interface ExportButtonProps {
  structure: StructureData | undefined;
  data: TimeSeries | undefined;
}

export const ExportButton: FC<ExportButtonProps> = ({ structure, data }) => {
  const handleClick = () => {
    const raw = exportRaw(structure, data);
    const file = new File([JSON.stringify(raw)], "trackvis.json",  { type: "application/json"});
    saveAs(file);
  };

  return (
    <button onClick={handleClick}>Export</button>
  );
}
