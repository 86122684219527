import { memo, useMemo, useState } from "react";
import { DataMap, GroupData, TrackerData, TrackerGraphicalData, TrackerSlopeData, TrackerStructureMap, TrackerTimeData } from "../types";
import { isDistance } from "../util";
import { Slope } from "./slope";
import { Tracker } from "./tracker";

export interface TrackerContainerProps {
  group: GroupData;
  baseHeight: number;
  poleHeight: number;
  scaleFactor: number;
  data: DataMap | undefined;
  solarAngle: number | null;
}

export const TrackerContainer:React.FC<TrackerContainerProps> = memo(({ group, data, ...options }) => {
  const trackers = [];
  let offset = 0;

  for(const [index, entry] of group.entries()) {
    if(isDistance(entry)) {
      offset += entry;
    } else {
      trackers.push(<Tracker key={entry.id} tracker={entry} angle={data?.get(entry.id)} x={offset} {...options} />);
    }
  }

  return <>
    {trackers}
  </>;
});
