import { FC, useCallback, useMemo } from "react";
import { GroupData, TrackerData } from "../types";

export interface DropdownProps {
  groups: Map<string,GroupData> | undefined;
  value: string | undefined;
  update: (value: string) => void;
}

export const Dropdown: FC<DropdownProps> = ({ groups, value, update }) => {
  const entries = useMemo(() => {
    if(groups == null) {
      return [];
    }

    const entries = [...groups.entries()];

    entries.sort(([aId, aGroup], [bId, bGroup]) => {
      const aFirst = aGroup.values().next().value as TrackerData;
      const bFirst = bGroup.values().next().value as TrackerData;
      return aFirst.id.localeCompare(bFirst.id, undefined, { sensitivity: 'base', numeric: true });
    });

    return entries;
  }, [groups]);

  const options = useMemo(() => {
    return entries.map(([key, group]) => {
      const groupArray = [...group.values()];
      const first = groupArray[0] as TrackerData;
      const last = groupArray[groupArray.length-1] as TrackerData;
      return <option key={key} value={key}>{first.id} to {last.id}</option>;
    });
  }, [entries]);

  const onChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    update(e.target.value);
  }, [update]);

  return (
    <select onChange={onChange}>
      {options}
    </select>
  );
}
