import { DateTime } from "luxon";
import { pick } from 'lodash';

import { DataMap, GPSPosition, StructureData, TimeSeries, TrackerData } from "./types";
import { isDistance } from "./util";

export const importAny = (raw: string): { data?: TimeSeries, structure?: StructureData } => {
  if(raw.startsWith("Date;")) {
    const data = importCsvData(raw);
    return { data };
  } else {
    return importJson(raw);
  }
}

interface RawData {
  names: string[];
  data: Array<[string,Array<number|null>]>;
};

type RawTrackerData = Omit<TrackerData,'id'|'groups'>;

interface RawStructure {
  name?: string | undefined;
  groups?: Record<string,Array<string|number>>;
  trackers?: Record<string,RawTrackerData>;
  position?: GPSPosition;
  data?: RawData;
}

export const exportJson = (structure: StructureData | undefined, timeSeries: TimeSeries | undefined): string => {
  return JSON.stringify(exportRaw(structure, timeSeries));
}

export const exportRaw = (structure: StructureData | undefined, timeSeries: TimeSeries | undefined): RawStructure => {
  let groups: Record<string,Array<string|number>> | undefined;
  let trackers: Record<string,RawTrackerData> | undefined;
  let position: GPSPosition | undefined;
  let data: RawData | undefined;
  let name: string | undefined;

  if(structure != null) {
    groups = {};
    trackers = {};
    position = structure.position;
    name = structure.name;

    for(const [groupId, group] of structure.groups.entries()) {
      const rawGroup = groups[groupId] = Array<string|number>();

      for(const entry of group) {
        if(isDistance(entry)) {
          rawGroup.push(entry);
        } else {
          trackers[entry.id] = pick(entry, ['width', 'height', 'azimuth']);
          rawGroup.push(entry.id);
        }
      }
    }
  }

  if(timeSeries != null) {
    const idSet = new Set<string>();

    for(const entry of timeSeries) {
      for(const id of entry.data.keys()) {
        idSet.add(id);
      }
    }

    data = {
      names: [...idSet],
      data: [],
    };

    for(const entry of timeSeries) {
      const rawCells = Array<number|null>();

      for(const id of idSet) {
        rawCells.push(entry.data.get(id) ?? null);
      }

      data.data.push([entry.time.toISO(), rawCells]);
    }
  }

  return { groups, trackers, position, name, data };
}

export const importJson = (raw: string): { data?: TimeSeries, structure?: StructureData } => {
  const parsed = JSON.parse(raw) as RawStructure;
  return importRaw(parsed);
}

export const importRaw = (raw: RawStructure): { data?: TimeSeries, structure?: StructureData } => {
  const data = raw.data != null ? importRawData(raw.data) : undefined;
  const structure = importRawStructure(raw);
  return { structure, data };
}

export const importRawStructure = (raw: RawStructure): StructureData | undefined => {
  if(raw.groups == null || raw.trackers == null || raw.position == null) {
    return undefined;
  }

  const trackerEntries = Object.entries(raw.trackers).map(([id, tracker]): [string,TrackerData] => {
    const groups = new Set<string>();
    return [id, { id, groups, ...tracker }];
  })

  const trackers = new Map(trackerEntries);

  const groupEntries = Object.entries(raw.groups).map(([key, rawEntries]): [string, Array<TrackerData|number>]  => {
    const entries = rawEntries.map((entry) => {
      if(typeof entry === 'number') {
        return entry;
      } else {
        const tracker = trackers.get(entry)!;
        tracker.groups.add(key);
        return tracker;
      }
    });

    while(typeof entries[0] === 'number') {
      entries.shift();
    }

    while(typeof entries[entries.length-1] === 'number') {
      entries.splice(-1);
    }

    return [key, entries];
  });

  return {
    name: raw.name,
    groups: new Map(groupEntries),
    position: raw.position,
  };
}

export const importRawData = (raw: RawData): TimeSeries => {
  const result: TimeSeries = [];

  for(const [when, entries] of raw.data) {
    const data: DataMap = new Map();

    for(const [index, entry] of entries.entries()) {
      if(entry == null) {
        continue;
      }

      data.set(raw.names[index], entry);
    }

    result.push({
      time: DateTime.fromISO(when),
      data,
    });
  }

  return result;
}

export const importCsvData = (raw: string): TimeSeries => {
  const lines = raw.split('\n');
  const ids = lines[0].split(';');

  const result: TimeSeries = [];

  for(const line of lines.slice(2)) {
    const current = line.split(';');

    if(current.length <= 2) {
      continue;
    }

    const timeStr = `${current[0]};${current[1]}`;
    const time = DateTime.fromFormat(timeStr, 'dd.MM.yyyyy;HH:mm:ss');

    const data: DataMap = new Map();

    for(const [index, entry] of current.entries()) {
      if(index < 2) {
        // date+time
        continue;
      }

      if(entry.length === 0) {
        // empty column
        continue;
      }

      const id = ids[index];
      data.set(id, Number(entry));
    }

    result.push({ time, data });
  }

  while(result.length > 0 && result[0].data.size == 0) {
    result.shift();
  }

  while(result.length > 0 && result[result.length-1].data.size == 0) {
    result.splice(-1);
  }

  return result;
}

