import { useMemo } from "react";
import { exportJson, importJson } from "../import";

import { StructureData, TimeSeries } from "../types";

const STORE_ID = 'trackvis_persist';

export const useGetPersist = () => {
  return useMemo((): { structure?: StructureData, data?: TimeSeries } => {
    try {
      const stored = localStorage.getItem(STORE_ID);

      if(stored == null) {
        return {};
      }

      return importJson(stored);
    } catch(err) {
      // TODO
      return {};
    }
  }, []);
};

export const useStorePersist = (structure: StructureData | undefined, data: TimeSeries | undefined) => {
  try {
    const raw = exportJson(structure, data);
    localStorage.setItem(STORE_ID, raw);
  } catch(err) {
    // TODO
  }
}
