import { useEffect, useMemo, useRef, useState } from 'react';
import './App.css';
import { useAngles, useStructure } from './backend';
import { QueryClient, QueryClientProvider } from 'react-query';
import { TimeSlider } from './components/slider';
import { StructureData, TimeSeries, TrackerData, TrackerStructure, TrackerStructureMap, TrackerTimeData } from './types';
import { DateTime } from 'luxon';
import { Dropdown } from './components/dropdown';
import { getSolarAngle } from './solar_angle';
import { GroupView, TrackClickEvent } from './components/group_view';
import { ExportButton } from './components/export_button';
import { ImportButton } from './components/import_button';
import { importJson } from './import';
import { useGetPersist, useStorePersist } from './components/persist';

function Main() {
  const defaultData = useGetPersist();

  const [structure, setStructure] = useState<StructureData|undefined>(defaultData.structure);
  const [angles, setAngles] = useState<TimeSeries|undefined>(defaultData.data);

  const [dataIndex, setDataIndex] = useState(0);
  const [selectedGroup, setSelectedGroup] = useState<string>();
  const [autoRun, setAutoRun] = useState(false);
  const [availableHeight, setAvailableHeight] = useState<number>();
  const [visibleArea, setVisibleArea] = useState<[number,number]>();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const position = structure?.position;
  const groups = structure?.groups;

  const group = selectedGroup ? groups?.get(selectedGroup) : undefined;
  const data = angles != null ? angles[dataIndex] : undefined;

  useStorePersist(structure, angles);

  useEffect(() => {
    if(angles == null) {
      return;
    }

    setDataIndex(Math.floor(angles.length * 0.1));
  }, [angles]);

  useEffect(() => {
    if(groups == null) {
      return;
    }

    if(selectedGroup == null || groups.get(selectedGroup) == null) {
      setSelectedGroup(groups.keys().next().value);
    }
  }, [groups]);

  useEffect(() => {
    const dom = wrapperRef.current;

    if(dom == null) {
      return;
    }

    const handleResize = () => {
      setAvailableHeight(dom.getBoundingClientRect().height);
    };

    handleResize();

    const listener = window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [wrapperRef.current]);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    const parent = wrapper?.parentElement;

    if(wrapper == null || parent == null) {
      return;
    }

    const update = () => {
      const from = parent.scrollLeft / wrapper.clientWidth;
      const to = from + parent.clientWidth / wrapper.clientWidth;

      setVisibleArea([from, to]);
    };

    update();

    parent.addEventListener('scroll', update);

    return () => {
      parent.removeEventListener('scroll', update);
    };
  }, [wrapperRef.current, group]);

  useEffect(() => {
    if(!autoRun) {
      return;
    }

    if(angles == null) {
      setAutoRun(false);
      return;
    }

    const timeout = setTimeout(() => {
      if(dataIndex < angles.length - 1) {
        setDataIndex(dataIndex + 1);
      } else {
        setAutoRun(false);
      }
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, [angles, autoRun, dataIndex]);

  const timeEntries = useMemo((): DateTime[] => {
    let result: DateTime[] = [];
    angles?.forEach((e) => {
      if (e.time !== undefined) {
        result.push(e.time);
      }
    });
    return result;
  }, [angles]);

  const handleClick = (event: TrackClickEvent) => {
    if(wrapperRef.current == null) {
      return;
    }

    const wrapper = wrapperRef.current;
    const parent = wrapper.parentElement;

    if(parent == null) {
      return;
    }

    parent.scrollLeft = wrapper.clientWidth * event.x - parent.clientWidth / 2;
  };

  return <>
    <div className="preview">
      <GroupView position={position} group={group} time={data?.time} data={data?.data} canvasHeight={200} onTrackClick={handleClick} visibleArea={visibleArea} allowScale />
    </div>
    <div className="stage">
      <div className="svg_wrapper" ref={wrapperRef}>
        <GroupView position={position} group={group} time={data?.time} data={data?.data} canvasHeight={availableHeight} baseLines labels />
      </div>
    </div>
    <div className="control">
      <div className="time">
        <span>{angles ? angles[dataIndex]?.time.toISO() : undefined ?? '-'}</span>
        <TimeSlider data={angles} value={dataIndex} update={setDataIndex} />
        <button onClick={() => setAutoRun(!autoRun)}>{autoRun ? "Pause" : "Play"}</button>
      </div>
      <div className="group">
        <Dropdown groups={groups} value={selectedGroup} update={setSelectedGroup} />
      </div>
      <div className="data">
        {structure ? structure.name ?? "Unknown plant" : "No data loaded"}
        <ImportButton setStructure={setStructure} setData={setAngles} />
        <ExportButton structure={structure} data={angles} />
      </div>
    </div>
  </>
}

function App() {
  return (
    <Main />
  );
}

export default App;
